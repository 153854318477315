<template>
  <div>
    <div v-if="isMulti">
      <el-upload
        action="#"
        :file-list="fileList"
        :on-success="onSuccess"
        list-type="picture-card"
        :http-request="fileUpLoad"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :before-upload="beforeAvatarUpload"
        :on-error="handleError"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
    <div v-else>
      <el-upload class="avatar-uploader" action="#" :show-file-list="false" :on-success="onSuccess" :http-request="fileUpLoad">
        <img v-if="imageUrl || url" :src="imageUrl || url" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { getAliOssSts } from '@/components/file-upload/api'
import ossClient, { getFilePath, getSystemPath } from './oss'
import { localget, localset } from '@/utils/localStorage'
import * as imageConversion from 'image-conversion'
import { Loading } from 'element-ui'
export default {
  name: 'FileUpload',
  // isMulti:是否上传多张；name：文件名，url：文件链接
  // 如果isMulti==true, name和url应该是个数组，否则为字符串
  props: ['isMulti', 'name', 'url'],

  data() {
    return {
      // 单张图片的封面
      imageUrl: '',
      // 多张图片的封面
      fileList: [],
      // oss上传配置项
      uploadConf: {},
      // 多张图片预览dialog
      dialogImageUrl: '',
      dialogVisible: false,
      list: [],
      loading: null
    }
  },
  watch: {
    url: {
      handler(newV, oldV) {
        // 如果是多张图片
        if (this.isMulti) {
          if (newV) {
            // 当监视到url有新值时给fileList赋值初始值
            this.fileList = newV.map((item, index) => {
              return {
                url: item,
                name: this.name.find((name, i) => index == i)
              }
            })
            this.list = this.fileList
          }
        }
      }
    }
  },
  mounted() {
    this.uploadConf = localget('alioss')

    if (!this.uploadConf) {
      getAliOssSts().then(res => {
        this.uploadConf = {
          region: 'oss-cn-hangzhou',
          accessKeyId: res.accessKeyId,
          accessKeySecret: res.accessKeySecret,
          bucket: res.bucket,
          stsToken: res.securityToken
        }

        localset('alioss', this.uploadConf, 1800)
      })
    }
  },
  methods: {
    handleError(err, file, fileList) {
      console.log('上传失败')
      if (this.loading) {
        this.loading.close()
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024
      return new Promise((resolve, reject) => {
        this.loading = Loading.service({
          lock: true, //是否锁定屏幕的滚动
          spinner: 'el-icon-loading',
          text: '上传中', //loading下面的文字
          background: 'rgba(0, 0, 0, 0.0)', //loading的背景色
          target: 'body' //loading显示在容器
        })
        if (isLt2M < 6) {
          // 小于6M不用压缩
          resolve(file)
        } else {
          //6140kb
          console.log('压缩')
          imageConversion.compressAccurately(file, 3072).then(res => {
            console.log('大于6M进行压缩', res)
            resolve(res)
          })
        }
      })
      // if (!isJPG) {
      //   this.$message.error('上传图片只能是 JPG 格式!')
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传图片大小不能超过 6MB!')
      // }

      // return isJPG && isLt2M
      // return isLt2M
    },
    onSuccess(res, file, filelist) {
      if (this.loading) {
        this.loading.close()
      }
      if (this.isMulti) {
        this.fileList = filelist
        // this.list.push({
        //   url: res.url,
        //   name: res.name,
        //   fileUid: file.uid
        // })
        // console.log(this.list)

        // const nameList = this.list.map(item => {
        //   return item.name
        // })
        const nameList = filelist.map(item => {
          if (item.response) {
            return item.response.name
          }
        })

        this.$emit('fileUpload', this.list)
        this.$emit('update:name', nameList)
      } else {
        this.imageUrl = res.url
        this.file = {
          url: res.url,
          name: res.response.name,
          fileUid: file.uid
        }
        this.$emit('fileUpload', this.file)
        this.$emit('update:name', this.file.name)
      }
    },

    //上传文件操作，调用阿里云
    fileUpLoad(option) {
      //提前拼接返回地址
      let filePath = getFilePath(this.$route.name, option.file.name)
      if (option.file.size > 6291456) {
        this.$message.error('资源文件大小超出范围')
        return
      }
      console.log('@@@@', option.file)
      return ossClient(this.uploadConf).put(filePath, option.file)
    },

    handleRemove(file, fileList) {
      console.log(fileList)
      this.fileList = fileList
      this.list = this.fileList

      const nameList = this.list.map(item => {
        if (item.response) {
          return item.response.name
        }
      })
      this.$emit('update:name', nameList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    compressImg(file) {
      const reader = new FileReader()
      // readAsDataURL 方法会读取指定的 Blob 或 File 对象。读取操作完成的时候，readyState 会变成已完成DONE，并触发 loadend (en-US) 事件，
      // 同时 result 属性将包含一个data:URL格式的字符串（base64编码）以表示所读取文件的内容。
      reader.readAsDataURL(file)
      reader.onload = () => {
        const img = new Image()
        img.src = reader.result
        img.onload = () => {
          // 图片的宽高
          const w = img.width
          const h = img.height
          const canvas = document.createElement('canvas')
          // canvas对图片进行裁剪，这里设置为图片的原始尺寸
          canvas.width = w * 0.8
          canvas.height = h * 0.8
          const ctx = canvas.getContext('2d')
          // canvas中，png转jpg会变黑底，所以先给canvas铺一张白底
          ctx.fillStyle = '#fff'
          // fillRect()方法绘制一个填充了内容的矩形，这个矩形的开始点（左上点）在
          // (x, y) ，它的宽度和高度分别由width 和 height 确定，填充样式由当前的fillStyle 决定。
          ctx.fillRect(0, 0, canvas.width, canvas.height)
          // 绘制图像
          ctx.drawImage(img, 0, 0, w, h)

          // canvas转图片达到图片压缩效果
          // 返回一个包含图片展示的 data URI base64 在指定图片格式为 image/jpeg 或 image/webp的情况下，
          // 可以从 0 到 1 的区间内选择图片的质量。如果超出取值范围，将会使用默认值 0.92。其他参数会被忽略。
          const dataUrl = canvas.toDataURL('image/jpeg', 0.8)
          this.dialogImageUrl = dataUrl

          // base64格式文件转成Blob文件格式
          let blobFile = this.dataURLtoBlob(dataUrl)
          console.log('压缩后的图片：Blob文件----------')
          console.log(blobFile)
          // base64格式文件转成file文件格式
          let fileName = this.sourceFile.name
          let fileImg = this.dataURLtoFile(dataUrl, fileName)
          console.log('压缩后的图片：file文件----------')
          console.log(fileImg)
          this.compressFile = fileImg
        }
      }
    },
    // canvas生成的格式为base64，需要进行转化, base64->file
    dataURLtoFile(dataurl, fileName) {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], fileName, { type: mime })
    },
    // canvas生成的格式为base64，需要进行转化, base64->blob
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
